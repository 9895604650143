/** @format */
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,200;0,400;0,600;1,500&family=Montserrat+Subrayada&family=Poor+Story&display=swap");

@font-face {
  font-family: "DanielFont";
  src: url("./fonts/circular-std-medium-500.ttf") format("truetype");
  /* You can also specify other font formats here if you have them available */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
